import React from 'react'
import Layout, { LAYOUT_TYPES } from '../modules/Layout'
import { graphql } from 'gatsby'
import Main from '../modules/Main'
import useSeo from '../hooks/useSeo'
import { TEMPLATE_TYPES } from '../common/constants'

const DATA_MAP = {
  title: ['name'],
}

const getLayout = (data, helmetProps) => {
  return [
    ...data.layout,
    {
      __typename: LAYOUT_TYPES.HELMET,
      ...helmetProps,
    },
  ]
}

const Sitemap = (props) => {
  const {
    data: { contentfulPage },
  } = props

  const url = props.location.href
  const seoProps_Page = useSeo(contentfulPage, DATA_MAP, TEMPLATE_TYPES.PAGE)
  const layout = getLayout(contentfulPage, seoProps_Page)

  return (
    <Main {...props} path={url}>
      <Layout layout={layout} path={url} />
    </Main>
  )
}

export default Sitemap

export const pageQuery = graphql`
  query {
    contentfulPage(slug: { eq: "not-found" }) {
      id
      slug
      name
      layout {
        __typename
        ... on ContentfulHero {
          ...Hero
        }
        ... on ContentfulContentGeneric {
          id
          ...ContentGeneric
        }
      }
    }
  }
`
